import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import TextContainer from './TextContainer'

const Note = ({ data }) => {
  const { paragraphs } = data
  return (
    <TextContainer>
      <S.Note>{paragraphs}</S.Note>
    </TextContainer>
  )
}

const S = {}

S.Note = styled.div`
  border-left: 5px solid ${th('colors.pink')};
  padding-left: 30;
  list-style: disc;

  ${breakpoints({
    xs: css`
      font-size: 20;
      line-height: 1.5;
    `,
    md: css`
      font-size: 32;
    `,
  })}
`

export default Note
