import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

import ImageSection from '@atoms/ImageSection'
import Layout from '@organisms/Layout'
import Hero from '@organisms/ProjectSingle/Hero'
import Copy from '@organisms/ProjectSingle/Copy'
import SocialContainer from '@organisms/ProjectSingle/SocialContainer'
import Quote from '@organisms/ProjectSingle/Quote'
import Note from '@organisms/ProjectSingle/Note'
import idgen from '@utils/idgen'

const ProjectPage = ({ data }) => {
  const { blocks, meta, coverImage } = data.projects.nodes[0].frontmatter

  return (
    <Layout hamburgerMenu meta={{ ...meta }} buttonColor="dark">
      <S.Main>
        {blocks && blocks.length > 0
          ? blocks.map((item) => {
              switch (item.type) {
                case 'hero':
                  return <Hero data={item} key={idgen()} />
                case 'copy':
                  return <Copy data={item} key={idgen()} />
                case 'fullImage':
                  return (
                    <ImageSection
                      full
                      key={idgen()}
                      image={item.image.childImageSharp.gatsbyImageData}
                    />
                  )
                case 'image':
                  return (
                    <ImageSection
                      key={idgen()}
                      image={item.image.childImageSharp.gatsbyImageData}
                    />
                  )
                case 'quote':
                  return <Quote key={idgen()} data={item} />
                case 'note':
                  return <Note key={idgen()} data={item} />
                default:
                  return null
              }
            })
          : null}

        <SocialContainer />
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.main`
  display: grid;
  grid-template-rows: auto;
  ${breakpoints({
    xs: css`
      padding-top: 160;
      padding-bottom: 100;
      row-gap: 50;
    `,
    md: css`
      padding-top: 252;
      padding-bottom: 130;
      row-gap: 130;
    `,
  })}
`

export const pageQuery = graphql`
  query ($slug: String!) {
    projects: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
  }
`

export const repeaterFragment = graphql`
  fragment RepeaterFragment on MarkdownRemarkFrontmatter {
    title
    collection
    excerpt
    keywords
    coverImage {
      childImageSharp {
        gatsbyImageData(width: 800, layout: CONSTRAINED)
      }
    }
    meta {
      title
      description
      image {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
    }
    blocks {
      section
      sectionTitle
      button
      buttonDesktop
      buttonLink
      linkButton
      linkUrl
      caption
      logo {
        childImageSharp {
          gatsbyImageData(width: 200, layout: FIXED)
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      sideImage: image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 510)
        }
      }
      paragraphs
      title
      subtitle
      inverse
      type
      cite
      avatar {
        childImageSharp {
          gatsbyImageData(width: 50, height: 50, layout: FIXED)
        }
      }
      invertBackground
      company
      copy
      position
      list
      url
      items {
        type
        title
        inverse
        paragraphs
        email
        phone
        buttonLink
        button
        status
        linkButton
        linkUrl
        paragraphs
        caption
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }

        alt
        logo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      videoThumbnail {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        publicURL
        internal {
          mediaType
        }
      }
      videoPreview {
        publicURL
        internal {
          mediaType
        }
      }
      videoSourceYouTubeID
      color
    }
  }
`

export default ProjectPage
