import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { ifProp } from 'styled-tools'
import { rem } from 'polished'
import { breakpoints } from '@xstyled/system'
import { Container } from '@atoms/Grid'

const TextContainer = ({ children, home }) => {
  return (
    <S.Wrapper>
      <S.Container home={home}>{children}</S.Container>
    </S.Wrapper>
  )
}

const S = {}

S.Wrapper = styled.section``

S.Container = styled(Container)`
  display: flex;
  flex-direction: column;
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      max-width: 568px;
    `,
    lg: css`
      max-width: ${ifProp('home', rem('996px'), rem('792px'))};
    `,
  })}
`

export default TextContainer
