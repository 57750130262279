import React from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import Social from '@molecules/Social'
import TextContainer from './TextContainer'

const SocialContainer = () => {
  const icons = ['facebook', 'linkedin', 'twitter']
  return (
    <TextContainer>
      <S.Social icons={icons} />
    </TextContainer>
  )
}

const S = {}

S.Social = styled(Social)`
  svg {
    ${breakpoints({
      xs: css`
        width: 22;
        height: 22;
      `,
      md: css`
        width: 24;
        height: 24;
      `,
    })}
  }
`

export default SocialContainer
