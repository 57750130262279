import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { TitleContent } from '@atoms/Typography'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'
import TextContainer from './TextContainer'

const Hero = ({ data }) => {
  const { title, paragraphs } = data
  return (
    <TextContainer>
      {title && <S.Title>{title}</S.Title>}
      <S.HTMLContent
        dangerouslySetInnerHTML={{ __html: transformMDtoHTML(paragraphs) }}
      />
    </TextContainer>
  )
}

const S = {}

S.Title = styled(TitleContent)`
  line-height: 1.5;
`

S.HTMLContent = styled(HTMLContent)`
  p {
    ${breakpoints({
      xs: css`
        font-size: 16;
        line-height: 1.5;
      `,
      md: css`
        font-size: 19;
      `,
    })}

    > a {
      border-bottom: 1px solid ${th('colors.grey')};
      &:hover {
        border-color: ${th('colors.dark')};
      }
    }
  }
`

export default Hero
