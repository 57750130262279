import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { H2 } from '@atoms/Typography'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'
import TextContainer from './TextContainer'

const Hero = ({ data }) => {
  const { title, paragraphs } = data
  return (
    <TextContainer>
      <S.Title as="h1">{title}</S.Title>
      <S.HTMLContent
        dangerouslySetInnerHTML={{ __html: transformMDtoHTML(paragraphs) }}
      />
    </TextContainer>
  )
}

const S = {}

S.Title = styled(H2)`
  ${breakpoints({
    xs: css`
      margin-bottom: 20;
    `,
    md: css`
      margin-bottom: 20;
    `,
  })}
`

S.HTMLContent = styled(HTMLContent)`
  p {
    ${breakpoints({
      xs: css`
        font-size: 18;
        line-height: 1.5;
      `,
      md: css`
        font-size: 24;
      `,
    })}
  }
`

export default Hero
